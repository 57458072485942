<template>
	<div class="checkbox-element">
		<div class="title-container">
			<div class="container">
				<h1 class="title">Checkbox-Element</h1>
				<h3 class="subtitle">Mehrere Elemente auswählen</h3>
			</div>
			<button @click="$emit('delete')" class="delete-button">
				<img src="../../assets/icons/delete.svg" />
			</button>
		</div>
		<hr class="title-content" />
		<ul class="checkbox-list">
			<li v-for="checkbox in page.elementData" :key="checkbox.id">
				<IconSelection v-model="checkbox.icon" :element="checkbox" />
				<input
					v-model="checkbox.data"
					@input="modifyElementData(checkbox.id, checkbox.data)"
					class="checkbox-text"
				/>
				<button @click="removeCheckbox(checkbox.id)" class="delete-button">
					<img src="../../assets/icons/delete.svg" />
				</button>
				<div class="icon-container">
					<img class="menu-icon" src="../../assets/icons/menu.svg" />
				</div>
			</li>
		</ul>
		<button @click="addCheckbox" class="new-checkbox">
			<img src="../../assets/icons/add-circle-sharp.svg" />
			<span>Neue Antwort</span>
		</button>
	</div>
</template>

<script>
import IconSelection from './IconSelection';
import { mapActions } from 'vuex';
import { debounce } from 'lodash';

export default {
	name: 'CheckboxElement',
	components: {
		IconSelection
	},
	props: {
		project: Object,
		survey: Object,
		page: Object
	},
	methods: {
		...mapActions(['addElement', 'deleteElement', 'changeElementData']),
		addCheckbox: function () {
			this.addElement({
				projectID: this.project.id,
				surveyID: this.survey.id,
				pageID: this.page.id
			});
		},
		removeCheckbox: function (checkboxID) {
			this.deleteElement({
				projectID: this.project.id,
				surveyID: this.survey.id,
				pageID: this.page.id,
				elementID: checkboxID
			});
		},
		modifyElementData: debounce(function (elementID, data) {
			this.changeElementData({ elementID, data });
		}, 400)
	}
};
</script>

<style lang="scss" scoped>
.checkbox-element {
	padding: 20px;

	background: $unnamed-color-1a1a1a 0% 0% no-repeat padding-box;
	border: 1px solid $unnamed-color-999999;
	border-radius: 4px;

	margin-bottom: 40px;

	.delete-button {
		height: 48px;
		width: 48px;

		background: #321308 0% 0% no-repeat padding-box;
		border-radius: 4px;
		border: none;

		cursor: pointer;
		color: #fc6027;

		img {
			margin-top: 4px;
		}
	}

	.title-container {
		@include flex-between-center;

		.container {
			.title {
				@include bold-text-uppercase-22;
				color: $unnamed-color-999999;
			}

			.subtitle {
				@include normal-text-15;
				color: $unnamed-color-999999;
			}
		}
	}

	.title-content {
		margin-top: 20px;
		margin-bottom: 30px;
	}

	.checkbox-list {
		list-style: none;

		li {
			margin-top: 20px;
			height: 68px;
			border-bottom: 1px $unnamed-color-333333 solid;

			@include flex-top;

			.checkbox-text {
				width: 100%;
				height: 48px;

				margin-right: 10px;
				padding: 0 16px;

				border: 1px solid $unnamed-color-999999;
				background: #262626 0% 0% no-repeat padding-box;
				border-radius: 4px;

				@include normal-text-22;
				color: $unnamed-color-ffffff;
			}

			.icon-container {
				float: right;
				height: 48px;
				width: 48px;

				@include flex-center;

				.menu-icon {
					width: 18px;
					height: 12px;
				}
			}
		}
	}

	.new-checkbox {
		height: 48px;
		width: 226px;

		margin-top: 20px;
		padding-right: 20px;

		position: relative;
		text-align: right;

		background: $unnamed-color-fdc300 0% 0% no-repeat padding-box;
		border-radius: 4px;
		border: none;

		color: $unnamed-color-000000;
		cursor: pointer;

		span {
			@include bold-text-uppercase-22;
			color: inherit;
		}

		img {
			width: 24px;
			height: 24px;

			position: absolute;
			left: 20px;
			top: 50%;
			transform: translateY(-50%);
		}

		&:hover {
			background: #333333 0% 0% no-repeat padding-box;
			border: 2px solid black;
			border-radius: 4px;

			color: white;
		}
	}
}
</style>

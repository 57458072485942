<template>
	<div class="select" :tabindex="tabindex" @blur="open = false">
		<div class="selected" :class="{ open: open }" @click="open = !open">
			<div v-if="value == ''" class="dashed-empty"></div>
			<div v-else>
				<font-awesome-icon class="icon" :icon="['fas', value]" />
			</div>
		</div>
		<div class="items" :class="{ selectHide: !open }">
			<div
				@click="
					$emit('input', '');
					open = false;
				"
				class="item"
			>
				<div class="dashed-empty"></div>
			</div>
			<div
				v-for="(icon, index) in icons"
				:key="index"
				@click="
					$emit('input', icon);
					open = false;
				"
				class="item"
			>
				<font-awesome-icon class="icon" :icon="['fas', icon]" />
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
	name: 'IconSelection',
	props: {
		value: String,
		element: Object
	},
	data: function () {
		return {
			open: false,
			selected: null,
			tabindex: 0,
			icons: [
				'check',
				'home',
				'address-card',
				'align-justify',
				'angle-double-down'
			]
		};
	},
	watch: {
		value: function () {
			this.changeElementData({ elementID: this.element.id, icon: this.value });
		}
	},
	methods: {
		...mapActions(['changeElementData'])
	}
};
</script>

<style lang="scss" scoped>
.select {
	position: relative;

	width: 80px;
	height: 48px;
	margin-right: 10px;

	background: #262626 0% 0% no-repeat padding-box;

	.dashed-empty {
		border: 1px dashed $unnamed-color-fdc300;
		border-radius: 4px;
	}

	.icon {
		width: 25px;
		height: 25px;

		color: white;
	}

	.selected {
		width: 100%;
		height: 100%;

		border: 1px solid $unnamed-color-999999;
		border-radius: 4px;

		cursor: pointer;

		@include flex-left;

		div {
			width: 25px;
			height: 25px;

			margin-left: 10px;
		}

		&.open {
			border: 1px solid $unnamed-color-999999;
			border-radius: 4px 4px 0px 0px;
		}

		&::after {
			position: absolute;

			top: 22px;
			right: 10px;
			width: 0;
			height: 0;

			content: '';

			border: 5px solid transparent;
			border-color: $unnamed-color-999999 transparent transparent transparent;
		}
	}

	.items {
		position: absolute;
		left: 0;
		right: 0;
		z-index: 1;

		background: #262626 0% 0% no-repeat padding-box;
		border-right: 1px solid $unnamed-color-999999;
		border-left: 1px solid $unnamed-color-999999;
		border-bottom: 1px solid $unnamed-color-999999;
		border-radius: 0px 0px 6px 6px;

		overflow: hidden;

		&.selectHide {
			display: none;
		}

		.item {
			width: 100%;
			height: 48px;

			@include flex-center;
			cursor: pointer;

			border-bottom: 1px solid $unnamed-color-999999;

			div {
				width: 25px;
				height: 25px;
			}

			&:last-child {
				border-bottom: none;
			}

			&:hover {
				background-color: $unnamed-color-333333;
			}
		}
	}
}
</style>
